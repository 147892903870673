@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
.read{
    width: 15px;
    height: 15px;
    background: #6B6AE7;
    border-radius: 50%;
    position: absolute;
    margin-top: 12px;
    border: 2px solid black;
}

.unread{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: 12px;
    border: 2px solid black;
}

.up_arrow{
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    border-bottom: solid 5px #EA0101;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}

.down_arrow{
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    border-top: solid 5px #02bf42;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}


.card{
   padding : 22px;       
}


.react-bootstrap-table thead{
    background-color: #2FC3B9;
}

.react-bootstrap-table thead tr th{
    color:white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.react-bootstrap-table tbody{
   border: 0.5px solid #ebe3e3;
}
.react-bootstrap-table{
    margin-bottom: 15px;
}

div.ag-header-row{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #2FC3B9;
    color: white !important;
}

.ag-theme-alpine .ag-root-wrapper{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 1px 6px 1px 1px #ede9e9;
}

.ag-theme-alpine .ag-header{
    height: 65px;
    min-height: 65px;
}

.ag-header-row{
    height: 76px;
}

.ag-react-container div button:first-child{
    padding-top: 6px;
}

.ag-react-container div button:nth-child(2){
    margin-bottom: 10px;
}


/* // toggle button */
.custom-toggle input:checked + .custom-toggle-slider:before{
    background-color: white;
}

.custom-toggle input:checked + .custom-toggle-slider{
    background-color: #02bf42; 
    border:#02bf42
}


.ag-header-container{
    background-color: #6E68E7;
}

.ag-header-cell-text{
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
}
.ag-icon-filter{
    color: #fff;
}

.all_btn{
    width: 126px;
    height: 36px;
    left: 258px;
    top: 180px;
    background: #6B6AE7;
    border-radius: 8px 0px 0 8px;
    padding: 7px 50px;
    cursor: pointer;
    /* position: fixed; */
}

.all_txt{
    /* position: absolute; */
width: 29px;
height: 28px;
left: 307px;
top: 184px;

font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 35px;

/* color: #FFFFFF; */
}

.new_btn{
    width: 126px;
    /* position: fixed; */
    padding: 7px 50px;
    height: 36px;
    left: 436px;
    top: 181px;
    background: #D1D4F7;
    cursor: pointer;
}

.fav_btn{
    cursor: pointer;
    padding: 7px 50px;
    /* position: fixed; */
    width: 126px;
    height: 36px;
    left: 561px;
    top: 181px;
    background: #D1D4F7;
    border-radius: 0px 8px 8px 0px;
}

#primary{
    color: #fff;
    background: #6b6ae7;
}

#secondary{
    color: #000;
    background: #D1D4F7;
}


/* navbar main */
/* .navbar-top{
    background: linear-gradient(360deg, #88FDDE  0%, #08A9A8 80%);

 
} */
.auth{
    background: inherit !important;
}
/* header */
.header{
    background: #fff !important;
}
.mb-0{
    color:#3F3F3F;
}

/* filter input */
.ag-input-wrapper{
    color:grey;
}


/* profile page */
.bg-gradient-info{
    background: unset !important;
}


#sidenav-main {
   
    background: linear-gradient(180deg, #CCE7FF 10%, #DBF9FF 100%);
}

#sidenav-main h1 {

/* font-family: 'Heebo'; */
font-style: normal;
font-weight: 600;
font-size: 33px;
line-height: 53px;
/* identical to box height */
margin-bottom: 25%;
margin-top: 0%;
color: #0A5AC2;

}
.dash-button {
    color: #fff;
    background: #0A5AC2;
    border: #0A5AC2;
    border-radius:25px;

}
.phase-button {
    background: #fff;
    color: #3F3F3F;
    border: 1px solid #0A5AC2;
    border-radius:25px;

}
.am-link{
    color:#0A5AC2;
    text-align:left;
    padding-left: 0px;


line-height: 22px;
/* identical to box height */

text-decoration-line: underline;
}
.dash-analy{
    background: #F4F4F4;
border-radius: 8px;
padding: 1%;
/* transform: matrix(1, 0, 0, -1, 0, 0); */
}
.dash-col-analy,.dash-col-analy-3 .dash-inner-row {
    background: #FFFFFF;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 16px;
max-width: 100%;
margin:1%;

}
.clientformtag{
    text-align: left;
}
.dash-col-analy .active {
    background-color: #77fff81f !important;
}
.dash-col-analy{
    flex :0 0 19.66667% !important;
    max-width:  14%;
}
.dash-col-analy-3{
    flex :0 0 24.66667% !important;
    max-width:  25%;
}
.dash-inner-row{
    padding: 6%;
    /* width: 320px !important; */
}
.text-dash{
    color:#3F3F3F;
    font-size: 12px;
}
.dash-inner h1 {
    font-size: 32px;
}
.dash-inner h2 {
    font-size: 20px;
}
.card-header{
    padding: 0;
}
.main-content{
    background: #ffff;
}
.sortclass{
    cursor: pointer;
}
.filter-button{
    background: #F6F6F6 !important;
    border:#F6F6F6;
    border-radius:5px;
    color:#3F3F3F;
    padding: 6px !important;
}
.filter-button on:hover{
    background: #F6F6F6 !important;
    border:#F6F6F6;
    border-radius:5px;
    color:#3F3F3F
}
.taskdetailmodal{
    max-width :80%
}
.whitespace{
    white-space :unset !important;
}
.table td, .table th{
    /* white-space :unset !important; */
    padding: 7px 10px !important;
}
.table-data{
    white-space :unset !important;  
}
 /* tr:nth-child(3n+2) {
    background: #EDF7FF; 
    border-left: 7px solid #0060B8;
    border-radius: 4px 0px 0px 4px;
   } 
   tr:nth-child(3n+1) {
    background: #FFF9F2;
    border-left: 7px solid #F28705;
    border-radius: 4px 0px 0px 4px;
   } 
   tr:nth-child(3n) {
    background: #FEF5FF;
    border-left: 7px solid #D557EA;
    border-radius: 4px 0px 0px 4px;
   }
    */
    .form-control:disabled, .form-control[readonly] {
        background-color: #ffffff;
        opacity: 1;
        color: #acb0b4;
    }
    .disablerow {
        color: #e9ecef;
    }
    .edit-row span {
        padding: 10px;
        color:#878787;
        margin-right:25px;
        font-size: 14px;
    }
.navbar-top h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #444444;
}
.navbar-top .noti-span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #F24C05;
    cursor: pointer;
}
.nav-link{
   color: #3F3F3F;
   /* margin-left: 6.3%; */
   font-weight: 600 !important;
   font-size: 16px !important;
   padding: 7% !important;
   box-shadow: none !important;
 
}
.navbar-nav{
    margin-left:-0.5rem !important;
}
.text-th{
    color: #3F3F3F;
 
 }
 .footer{
     background: #ffffff !important;
 }
 .navbar-nav li a.active::before {
    content: "";
    top: -30px !important;
    right: 0 !important;
    left:153px !important;
    height: 30px;
    width: 30px;
    position: absolute;
    /* background: #2bdfd7; */
    border-radius: 50%;
    box-shadow: 15px 15px 0 #fff;
    border-left: 0px solid #2bdfd7 !important;
}

.navbar-nav li a.active::after {
    content: "";
    bottom: -30px;
    right: 0;
    height: 30px;
    width: 30px;
    position: absolute;
    /* background: #21d1cb; */
    border-radius: 50%;
    box-shadow: 15px -15px 0 #fff;
}
.navbar-vertical .navbar-nav .nav-link.active {
    position: relative;
    background-color: #FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 30px;
   
    border-bottom-left-radius: 30px;
}
.blue-row{
    background: #EDF7FF; 
    border-left: 7px solid #0060B8;
    border-radius: 4px 0px 0px 4px;

}
.orange-row{
    background: #FFF9F2;
    border-left: 7px solid #F28705;
    border-radius: 4px 0px 0px 4px;
}
.purple-row{
    background: #FEF5FF;
    border-left: 7px solid #D557EA;
    border-radius: 4px 0px 0px 4px;
}
.table .thead-light th {
    text-transform :none;
    background-color: #fff;
    color: #3F3F3F;
border: 2px solid #FFFFFF;
font-size: 14px;

}
.table .thead-fixed th{
    position: sticky; 
    top: 0; 
}
.table-resp{
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 100px;
    max-height: 600px;
}
.table th, .table td{
    padding:10px;
}
.stickytable td,th {
    border: 2px solid #FFFFFF;
    position: sticky;
    /* top:0; */
    }

.table td,th {
    border: 2px solid #FFFFFF;
    /* position: sticky; */
    top:0;
    }
.notify-footer{
   background-color: #21c1c7;
   padding: 20px;
}

.notify-footer span{
   color:white;
   font-size: 18px;
   cursor: pointer; 
}

.notify-header{
   display: block;
   border-bottom: 1px solid #e9e9e9;
   /* border-bottom: 1px solid grey;    */
}
.header-right-el{
    color: #21c1c7;
    font-size: 20px;
}
.header-right-el span{
   cursor: pointer;  
}

.header-right-el span:nth-child(2){
    margin-left: 50px;     
}

.btn-comment{
    background-color: #6b6ae7; 
    color: #e9e9e9; 
}

.btn-status{
    background-color: darkorange;
    color: #e9e9e9; 
}

.btn-c-style{
    width:160px;
}

.notify-count{
    border: 1px solid #cd2e2e;
    position: absolute;
    top: -11px;
    right: -4px;
    padding-top: 6px;
    text-align: center;
    background-color: #cd2e2e;
    color: white;
    font-size: 13px;
    width: 28px;
    border-radius: 50px;
    height: 28px;  
}
.filter{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control:disabled{
    background-color: #f1f1f16e;  
}
.btn-icon-clipboard{
    padding: 10px 20px 10px 20px;
    background-color: #F4F4F4;
    font-family: 'Inter';
}

.report-view-btn{
    border: 1px solid #2fc3b9;
    background:none;
    color: #2fc3b9;
    border-radius:18px;
    padding: 4px 16px;
    transition: all 0.25s;     
}

.report-view-btn:hover{
    border: 1px solid #2fc3b9;
    background: #2fc3b9;
    color: #ffffff;
    box-shadow: 0px 2px 10px 0px hsl(0deg 0% 15% / 29%);  
    transition: all 0.25s;
}

.custom-style tbody tr{
    /* box-shadow: inset 0px -3px 4px 0px hsl(0deg 0% 15% / 20%); */
    transition: all 0.25s;
 }

.custom-style tbody tr:hover{
   box-shadow: inset 0px -3px 4px 0px hsl(0deg 0% 15% / 8%);
   transition: all 0.25s;
}

.custom-style thead th{
   padding: 16px 10px !important; 
}

.custom-style tbody td{
    padding: 16px 10px !important;
}

.custom-style tbody tr td span.complete,span.allinforeceived,span.missinginfobutjobcanbestarted{
  color:#09a8a9;   
}

.custom-style tbody tr td span.inprogress{
  color:#fea732;   
}

.custom-style tbody tr td span.onhold,span.missinginfo{
    color:#ff1717;   
 }


@media (min-width: 768px){
.navbar-vertical .navbar-collapse:before {
    content: '';
    display: block;
    margin: 0rem -1rem;
}

.sider-logo{
    /* background: linear-gradient(360deg, #88FDDE  0%, #08A9A8 80%) !important; */
    margin-left: -37px;
    margin-top: -26px;
    line-height: 4;
    margin-right: -24px!important;
}
.theme-logo{
    margin-top: 18px;
}
.add-btn-ul{
    position: absolute;
    left: 2%;
    right: 76%;
    top: 79.49%;
    bottom: 0%;
    background: #6E68E7;
}
.edit-btn-ul{
    position: absolute;
    left: 2%;
    right: 82%;
    top: 79.49%;
    bottom: 0%;
    background: #6E68E7;
}
}

@media only screen and (max-width: 600px) {
    .notify-container {
      display: none;
    }
    #navbar-main{
        display: none;
    }
    .all_btn{
        width: 126px;
        height: 36px;
        left: 258px;
        top: 180px;
        background: #6B6AE7;
        border-radius: 8px 0px 0 8px;
        padding: 7px 30px;
        cursor: pointer;
        /* position: fixed; */
    }
    
    
    .new_btn{
        width: 126px;
        /* position: fixed; */
        padding: 7px 30px;
        height: 36px;
        left: 436px;
        top: 181px;
        background: #D1D4F7;
        cursor: pointer;
    }
    
    .fav_btn{
        cursor: pointer;
        padding: 7px 30px;
        /* position: fixed; */
        width: 126px;
        height: 36px;
        left: 561px;
        top: 181px;
        background: #D1D4F7;
        border-radius: 0px 8px 8px 0px;
    }
  }


  .browse-icon{
     font-size: 5.4rem;
     color: #2fc3b9;
  }
  .browse-title{
    font-size: 1.5rem;
  }
  .browse-btn{
    color: #ffffff !important;
    background-color: #2fc3b9 !important;
    font-weight: 450;
    font-size: 1.2rem;
  }

  .progress_css div{
    border-radius: 0px 0px 0px 0px !important;
  }
  
  .progress_css div div span{
    padding: 5px 16px !important;
  }

  .dz-ui-label{
    color: #2fc3b9 !important;
    font-family: inherit !important;
    font-size: 28px !important;
  }

  .dropzone-ui-extra {
    border: 1px dashed #d8d8df !important;  
  }
  .pagination {
    flex-wrap: wrap!important;
}

/* @media screen and (min-width: 1024px) and (max-height: 1310px) {
    .dash-col-analy{
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        width: 23% !important;
        height: 140px;
        margin:1%;
        max-width: 100%;
    }
    .dash-button {
        color: #fff;
        background: #05A8A8;
        border: #05A8A8;
        border-radius: 25px;
    }
}
 */
 .btn:hover {
    color: #fff;
    background-color: #FFB300; 
    border-color: #FFB300;
}
.btn-primary:hover {
    color: #fff;
    background-color: #FFB300; 
    border-color: #FFB300;
}
.btn-primary {
    color: #fff;
    background-color: #0A5AC2;
    border-color: #0A5AC2;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0A5AC2;
    border-color: #0A5AC2;
}
.btn-success {
    /* padding : 5px 10px; */
    color: #fff;
    background-color: #0A5AC2;
    border-color: #0A5AC2;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #0A5AC2;
    border-color: #0A5AC2;
}
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover, .rdtPicker .rdtTimeToggle:hover:before {
    background: #11cdef;
    color:white;
    cursor: pointer;
}
.question_table td,.question_table tr{
    border:1px solid black;
    padding: 5px 5px !important;
    
    
}
/* styles.css */

.quest_textarea {
    height: 30px !important;
    width: 100%;
    padding: 0.2rem 0.5rem;
 }
 @keyframes marquee {
    0% {
      margin-left: 100%;
      transform: translateX(0%);
    }
    100% {
      margin-left: 0;
      transform: translateX(-100%);
    }
  }
  
  .section {
    overflow: hidden;
    background-color: #CCE7FF;
    .marquee {
      animation: marquee 50s linear infinite;
      display: inline-block;
      white-space: nowrap;
  
      &:hover {
        animation-play-state: paused;
      }
    }
  }
  
  .text-styling {
    font-weight: bold;
    color:#5d6cae;
    margin:0;
    padding: 0.5em;
  }
  /* Container for the entire table */
.scroll-container {
    /* position: relative;
    overflow-x: auto;
    overflow-y: hidden; */

    /* width: 100%; */
    /* height: 900px;  */
    overflow: auto;
    white-space: nowrap;
    /* min-height: 400px;  
    max-height: 900px;  */
    
    
  }
  
  /* This will keep the content inline-block for scroll button to work */
  .scroll-content {
    display: inline-block;
    max-width: 100%; /* Prevent overflow issues */
  }
  
  .table-wrapper {
    display: block; /* Allow this div to scroll horizontally */
    overflow-x: auto; /* Horizontal scrolling */
    max-width: 100%; /* Prevent overflow */
  }
  
  /* Apply sticky positioning to the first 3 columns */
  .sticky-column {
    position: sticky;
    left: -4px;
    background-color: #f1f6f9;
    z-index: 15; /* Ensure sticky columns stay above other columns */
    /* border-right: 2px solid #ddd;  */
    border-style:none
    
    /* white-space: nowrap; */
  }
  .sticky-column-header {
    position: sticky;
    left: 0;
    z-index: 16; /* Ensure sticky columns stay above other columns */
    border-right: 2px solid #ddd; /* Optional: border to separate sticky columns */
    /* white-space: nowrap; */
  }
  
  /* Optional: Styles for alternating row colors */

  
  .scroll-button {
    background-color: #5d6cae;
    color: white;
    border: 1px solid white;
    z-index: 10; /* Ensure button is on top */
  }
  
  .scroll-div {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 55px;
    z-index: 1;
  }
  .sticky-table {
    /* width: 100%; */
    border-collapse: collapse;
    table-layout: fixed; /* Ensure the columns are fixed */
    height: auto;
  }
  
  .sticky-table td{
    font-size: .8125rem;
  }
  /* Sticky header styling */
  .sticky th {
    /* position: sticky; */
    /* top: 0;  */
    background-color: #fff; /* Background color to avoid transparency */
    /* z-index: 10; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
    /* padding: 10px;  */
    /* text-align: left;  */
    /* border: 1px solid #ddd;  */
    
    white-space: nowrap;
  }
 .sticky td, .sticky th {
    padding: 8px;
    /* border: 1px solid #ddd; */
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
    border: 2px solid #fff;
    
  }
  .sticky-table td {
    padding: 0px 10px 0px 10px;
    text-align: left;
    border: 2px solid #fff;
  } 
  .sticky-table th{
    text-align: left;
    border: 2px solid #fff;
  }
  .sticky-column:nth-child(0) {
    left: -1;
    z-index: 3; /* Ensure it stays above all other content */
    border-style:none;
    padding: 0px 10px 0px 10px;
  }  
  .sticky-column:nth-child(1) {
    left: -1;
    z-index: 4; /* Ensure it stays above all other content */
    border-style:none;
    padding: 0px 10px 0px 10px;
  }
  
  /* Make the second column sticky */
  .sticky-column:nth-child(2) {
    padding: 0px 10px 0px 10px;
    left: 34px; 
    z-index: 5;
    border-style:none
  }
  
  /* Make the third column sticky */
  .sticky-column:nth-child(3) {
    padding: 0px 10px 0px 10px;
    left: 143px;
    z-index: 6;
    border-style:none;
    white-space: nowrap;
    width: auto;
  }
  .sticky-column-header:nth-child(0) {
    left: -5;
    
  }  
  .sticky-column-header:nth-child(1) {
    left: -5;
    
  }
  
  /* Make the second column sticky */
  .sticky-column-header:nth-child(2) {
    left: 33px; 
    
  }
  
  /* Make the third column sticky */
  .sticky-column-header:nth-child(3) {
    left: 143px;

  }
  .z-index{
    z-index: 5;
  }
  .sticky-table-client{
    border-collapse: collapse;
    table-layout: fixed; /* Ensure the columns are fixed */
  }
.sticky-table-client th {
    padding: 8px;
    /* border: 1px solid #ddd; */
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
    border: 2px solid #fff;
    background-color: #efefef
    
  }
  .sticky-table-client td{
    padding: 8px;
    /* border: 1px solid #ddd; */
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: .8125rem;

    
    border-bottom: 1px solid #e9ecef;
    border: 2px solid #fff;
  }
  .sticky-client-column {
    position: sticky;
    left: -4px;
    background-color:#f9f9f9;
    z-index: 15; /* Ensure sticky columns stay above other columns */
    /* border-right: 2px solid #ddd;  */
    /* border-style:none */
    
    /* white-space: nowrap; */
    width: 150px;
  }
  .sticky-client-column-header {
   color:rgb(0, 128, 0);

    position: sticky;
    left: 0;
    background-color:white;
    z-index: 16; /* Ensure sticky columns stay above other columns */
    border-right: 2px solid #d60c0c; /* Optional: border to separate sticky columns */
    /* white-space: nowrap; */
  }
 
  .sticky-client-column:nth-child(1) {
    left: 55;
    z-index: 4; /* Ensure it stays above all other content */
    /* border-style:none; */
    padding: 0px 10px 0px 10px;
  }
  
  /* Make the second column sticky */
  .sticky-client-column:nth-child(2) {
    padding: 0px 10px 0px 10px;
    left: 88px; 
    z-index: 5;
    /* border-style:none */
    white-space: nowrap;
  }
  
  /* Make the third column sticky */
  .sticky-client-column:nth-child(3) {
    padding: 0px 10px 0px 10px;
    left: 190px;
    z-index: 6;
    /* border-style:none; */
    white-space: nowrap;
    width: auto;
  }
 
  .sticky-client-column-header:nth-child(1) {
    left: 55;
    z-index: 999;

    
  }
  
  /* Make the second column sticky */
  .sticky-client-column-header:nth-child(2) {
    left: 90px; 
    z-index: 999;
    
  }
  
  /* Make the third column sticky */
  .sticky-client-column-header:nth-child(3) {
    left: 190px;
    z-index: 999;
  }